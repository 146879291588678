import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/admin": [3],
		"/admin/brandsorting": [4],
		"/admin/createaudiencev2": [6],
		"/admin/createaudience": [5],
		"/admin/editaudiences": [7],
		"/admin/editfiltergroups": [8],
		"/admin/gtmmanagement": [9],
		"/admin/gtmmanagement/[container_id]": [10],
		"/admin/manualfigure": [11],
		"/admin/status": [12],
		"/admin/useraccess": [13],
		"/admin/usercontrols": [14],
		"/ads": [15],
		"/ads/[slug]": [16],
		"/artprints": [17],
		"/artprints/[filter]": [18],
		"/artprints/[filter]/[property]": [19],
		"/automation/apple/archives": [20],
		"/automation/apple/backups": [21],
		"/automation/apple/recommendations": [22],
		"/cached": [23],
		"/cached/[filter]": [24],
		"/cached/[filter]/[property]": [25],
		"/cancelledbreakout": [29],
		"/cancelledbreakout/[filter]": [30],
		"/cancelledbreakout/[filter]/[property]": [31],
		"/cancelled": [26],
		"/cancelled/[filter]": [27],
		"/cancelled/[filter]/[property]": [28],
		"/failed": [32],
		"/failed/[filter]": [33],
		"/failed/[filter]/[property]": [34],
		"/filteredview": [35],
		"/filteredview/orders": [38],
		"/filteredview/orders/[filter]": [39],
		"/filteredview/orders/[filter]/[property]": [40],
		"/filteredview/revper": [41],
		"/filteredview/revper/[filter]": [42],
		"/filteredview/revper/[filter]/[property]": [43],
		"/filteredview/[filter]": [36],
		"/filteredview/[filter]/[property]": [37],
		"/geo/country": [47],
		"/geo/country/[code]": [48],
		"/geo/[filter_country]": [44],
		"/geo/[filter_country]/[property]": [45],
		"/geo/[filter_country]/[property]/[code]": [46],
		"/language/locale": [52],
		"/language/locale/[code]": [53],
		"/language/[filter_locale]": [49],
		"/language/[filter_locale]/[property]": [50],
		"/language/[filter_locale]/[property]/[code]": [51],
		"/monitoring/commissionjunctionfulfillment": [54],
		"/monitoring/commissionjunctionorders": [55],
		"/monitoring/conversionstatus": [58],
		"/monitoring/conversions": [56],
		"/monitoring/conversions/[slug]": [57],
		"/monitoring/deployments": [59],
		"/monitoring/manualfigures": [60],
		"/monitoring/storedalarms": [61],
		"/monitoring/storedalarms/[property]": [62],
		"/notifications/create": [63],
		"/notifications/review": [64],
		"/notifications/review/[notification]": [65],
		"/orders": [66],
		"/orders/byplatform": [67],
		"/orders/bystore": [68],
		"/orders/commission": [69],
		"/orders/revper": [70],
		"/predictive": [71],
		"/predictive/new_purchaser": [72],
		"/predictive/new_purchaser/[filter]": [73],
		"/predictive/new_purchaser/[filter]/[property]": [74],
		"/predictive/weighted_average": [75],
		"/predictive/weighted_average/[model]/[start]/[end]": [76],
		"/product": [77],
		"/product/filtered": [79],
		"/product/filtered/[filter]": [80],
		"/product/filtered/[filter]/[property]": [81],
		"/product/filtered/[filter]/[property]/[product]": [82],
		"/product/[product]": [78],
		"/reports": [83],
		"/reports/amazonproductscustomreport": [86],
		"/reports/amazonproducts": [85],
		"/reports/amazon": [84],
		"/reports/awinordervalue": [87],
		"/reports/builder": [88],
		"/reports/builder/[start]/[end]/[granularity]/[sorting]/[platform]/[dates]": [89],
		"/reports/exchange": [90],
		"/reports/fiscalmonths": [91],
		"/reports/topline": [92],
		"/reports/walgreensdecayrate": [93],
		"/reports/walgreenspickuprate": [94],
		"/reports/walgreensstorepickuprate": [95],
		"/reports/walgreensstorepickuprate/[store]": [96],
		"/reports/ytd": [97],
		"/sentry-example": [98],
		"/stores": [99],
		"/stores/filtered": [100],
		"/stores/filtered/[platform]": [101],
		"/support": [102],
		"/support/disputes": [103],
		"/support/ticketflow": [104],
		"/troas": [105],
		"/troas/[platform]": [106],
		"/troas/[platform]/miccam/[single]": [109],
		"/troas/[platform]/[campaigns]": [107],
		"/troas/[platform]/[campaigns]/[single]": [108],
		"/users": [110],
		"/users/averages": [111],
		"/users/averages/[filter]": [112],
		"/users/new_per_day": [113],
		"/users/new_per_day/[filter]": [114],
		"/users/new_per_day/[filter]/[property]": [115]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';